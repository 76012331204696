'use client';

import React, {} from 'react';
import { CMS } from '@/types/CMS';
import Carousel from 'react-multi-carousel';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import FullWidthImage from './FullWidthImage';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1440 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 1440, min: 1080 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1080, min: 720 },
        items: 1
    },
    smallTablet: {
        breakpoint: { max: 720, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ButtonsGroup = ({ next, previous, ...rest }: any) => {
    const { carouselState: { currentSlide, slidesToShow, totalItems } } = rest;
    return totalItems > slidesToShow && (
        <>
            {currentSlide > 0 &&
                <button
                    type="button"
                    className="absolute left-[1rem] top-0 bottom-0 m-auto hidden md:block"
                    onClick={previous}
                >
                    <div className="w-[36px] h-[36px] rounded-full bg-white flex justify-center items-center">
                        <ChevronLeftIcon className="text-grey3 w-[20px] h-[20px]  rounded-full" />
                    </div>

                </button>
            }
            {(totalItems - currentSlide) > slidesToShow &&
                <button
                    type="button"
                    className="absolute right-[1rem] top-0 bottom-0 m-auto hidden md:block"
                    onClick={next}
                >
                    <div className="w-[36px] h-[36px] rounded-full bg-white flex justify-center items-center">
                        <ChevronRightIcon className="text-grey3 w-[20px] h-[20px]  rounded-full" />
                    </div>
                </button>
            }
        </>
    );
};

const FullWidthImageSlider = (props: { anchor?: string; data: CMS.Section.SectionsFullWidthImageSliderComponent }) => {
    const { anchor, data } = props;
    return (
        <div id={anchor} className="">
            <div className="mx-auto relative">
                <Carousel arrows={false} customButtonGroup={<ButtonsGroup />} autoPlaySpeed={6000} rewindWithAnimation rewind renderButtonGroupOutside renderDotsOutside ssr={false} autoPlay pauseOnHover showDots responsive={responsive} className="center-items-carousel" itemClass="mx-[0px]" dotListClass="pt-[20px]">
                    {data.slides.map((item) => <FullWidthImage key={item.id} data={item} />)}
                </Carousel>

            </div>
        </div>
    );
};

export default FullWidthImageSlider;
