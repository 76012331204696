/* eslint-disable @typescript-eslint/no-namespace */

export namespace CMS {
    export namespace Global {
        export interface Data {
            data: {
                id: number;
                attributes: DataAttributes;
            };
            navigation: Navigation;
        }
        export interface DataAttributes {
            createdAt: string; // Date
            updatedAt: string; // Date
            locale: string;
            metadata: SharedSeoComponent;
            favicon: CMS.Image.Image;
            loginPicture: CMS.Image.Image;
            signupPicture: CMS.Image.Image;
            navbar: Navbar;
            footer: Footer;
        }
        export interface SocialLink {
            id: number;
            url: string;
            newTab: boolean;
            text: string;
            social: string;
        }
        export interface RLogo {
            id: number;
            logoText: string;
            logoImg: CMS.Image.Image;
        }
        export interface Navbar {
            id: number;
            navbarLogo: RLogo;
        }
        export interface Footer {
            id: number;
            socialLinks: SocialLink[];
            footerLogo: RLogo;
        }
        export interface Navigation {
            footerMenu: Menu[];
            mainMenu: Menu[];
        }
        export enum MenuType {
            External = 'EXTERNAL',
            Internal = 'INTERNAL',
            Wrapper = 'WRAPPER',
        }
        export interface Menu {
            id: number;
            title: string;
            menuAttached: boolean;
            order: number;
            path: string;
            type: MenuType;
            uiRouterKey: string;
            // eslint-disable-next-line @typescript-eslint/ban-types
            slug: {} | string;
            external: boolean;
            items?: Menu[];
        }
        export interface SharedSeoComponent {
            id: number;
            metaTitle?: string;
            metaDescription?: string;
            shareImage?: CMS.Image.Image;
        }
    }
    export namespace Image {
        export interface Image {
            data: ImageData;
        }
        export interface ImageData {
            id: number;
            attributes: ImageAttributes;
        }
        export interface ImageAttributes {
            name: string;
            alternativeText: string;
            caption: null;
            width: number;
            height: number;
            formats?: Formats;
            hash: string;
            ext: ImageEXT;
            mime: ImageMIME;
            size: number;
            url: string;
            createdAt: string; // Date
            updatedAt: string; // Date
        }
        export enum ImageEXT {
            Jpg = '.jpg',
            PNG = '.png',
            SVG = '.svg',
        }
        export interface ImageFormat {
            ext: ImageEXT;
            url: string;
            hash: string;
            mime: ImageMIME;
            name: string;
            path: null;
            size: number;
            width: number;
            height: number;
        }
        export interface Formats {
            large?: ImageFormat;
            small?: ImageFormat;
            medium?: ImageFormat;
            thumbnail: ImageFormat;
        }
        export enum ImageMIME {
            ImageJPEG = 'image/jpeg',
            ImagePNG = 'image/png',
            ImageSVG = 'image/svg+xml',
        }
    }
    export namespace Page {
        export interface PageListResponse {
            data: Array<PageListResponseDataItem>;

            meta: CollectionListResponseMeta;
        }
        export interface CollectionListResponseMeta {
            pagination: CollectionListResponseMetaPagination;
        }
        export interface CollectionListResponseMetaPagination {
            page: number;

            pageSize: number;

            pageCount: number;

            total: number;
        }

        export interface PageListResponseDataItem {
            id: number;

            attributes: Page;
        }
        export interface Page {
            shortName?: string;
            contentSections: Array<Section.Section>;
            notificationBanner?: NotificationBanner;

            slug: string;

            heading?: string;

            description?: string;

            seo?: CMS.Global.SharedSeoComponent;

            landingPageSignupTracking?: string;

            skipOnboardingOnSignup?: boolean;

            allowSEOIndex?: boolean;

            redirect?: string;

            createdAt: string;

            updatedAt: string;

            publishedAt?: string;

            locale?: string;
        }

        export interface NotificationBanner {
            id: number;
            text: string;
            backgroundColor?: string;
            link?: string;
        }
    }
    export namespace Section {
        // eslint-disable-next-line max-len
        export type Section =
            | SectionsNewsletterComponent
            | SectionsBannerComponent
            | SectionsHeroSliderComponent
            | SectionsRichTextComponent
            | SectionsItemsCarouselComponent
            | SectionsBannerCarouselComponent
            | SectionsCollectionCarouselComponent
            | SectionsFaqComponent
            | SectionsThreeBlocksComponent
            | SectionsHeroComponent
            | SectionsRichTextV2Component
            | SectionsStatisticsComponent
            | SectionsFilterComponent
            | SectionsVideoHeroComponent
            | SectionsFullWidthImageComponent
            | SectionsFullWidthImageSliderComponent;
        export interface SectionsNewsletterComponent {
            id: number;

            __component: 'sections.newsletter';

            title: string;

            text: string;

            button: string;

            NewsletterType: string;

            variant?: SectionsNewsletterComponent.VariantEnum;

            background?: SectionsNewsletterComponent.BackgroundEnum;
        }
        export namespace SectionsNewsletterComponent {
            export enum VariantEnum {
                Full = 'full',
                EmailOnly = 'email-only',
            }

            export enum BackgroundEnum {
                Texture = 'texture',
                White = 'white',
                Dark = 'dark',
            }
        }
        export interface SectionsRichTextComponent {
            id: number;

            __component: 'sections.rich-text';

            content: string;

            addContainer?: boolean;
        }
        export interface SectionsRichTextV2Component {
            id: number;

            __component: 'sections.rich-text-v2';

            content: string;

            addContainer?: boolean;
        }
        export interface SectionsStatisticsComponent {
            id: number;

            __component: 'sections.statistics';

            Title: string;

            Text: string;

            Blocks: Array<ElementsStatisticsBlockComponent>;
        }
        export interface ElementsStatisticsBlockComponent {
            id: number;

            Metric: string;

            MetricDescription: string;
        }
        export interface SectionsThreeBlocksComponent {
            id: number;

            __component: 'sections.three-blocks';

            Items: Array<ElementsThreeBlocksItemComponent>;

            Title: string;

            Button: LinksButtonComponent;

            Type: SectionsThreeBlocksComponent.TypeEnum;
        }
        export interface ElementsThreeBlocksItemComponent {
            id: number;

            Icon: CMS.Image.Image;

            Title: string;

            Text: string;

            Border: boolean;

            Link?: string;
        }
        export namespace SectionsThreeBlocksComponent {
            export enum TypeEnum {
                TypeIcon = 'Icon',
                TypeImage = 'Image',
            }
        }
        export interface SectionsVideoHeroComponent {
            id: number;

            __component: 'sections.video-hero';

            VideoUrlGif: string;

            video: CMS.Image.Image;
        }
        export interface SectionsBannerComponent {
            id: number;

            __component: 'sections.banner';

            direction?: SectionsBannerComponent.DirectionEnum;

            light?: boolean;

            picture?: CMS.Image.Image;

            background: SectionsBannerComponent.BackgroundEnum;

            title: string;

            smallImage?: boolean;

            descriptionImage?: CMS.Image.Image;

            textTwo?: string;

            button?: LinksButtonComponent;

            video?: CMS.Image.Image;

            backgroundImage?: CMS.Image.Image;

            backgroundImageSizing: SectionsBannerComponent.BackgroundImageSizingEnum;

            text: string;

            mobilePicture?: CMS.Image.Image;

            maxHeight?: number;
        }
        export namespace SectionsBannerComponent {
            export enum DirectionEnum {
                Rtl = 'rtl',
                Ltr = 'ltr',
            }

            export enum BackgroundEnum {
                GradientBlue2 = 'bg-gradient-blue2',
                White = 'bg-white',
                GradientWhite = 'bg-gradient-white',
                Blue11 = 'bg-blue11',
            }

            export enum BackgroundImageSizingEnum {
                Fill = 'Fill',
                Fit = 'Fit',
            }
        }
        export interface SectionsCollectionCarouselComponent {
            id: number;

            __component: 'sections.collection-carousel';

            title?: string;

            titleLink?: string;

            hideSliderOnMobile?: boolean;

            collection: string;

            Sort?: SectionsCollectionCarouselComponent.SortEnum;

            Limit?: number;
        }
        export namespace SectionsCollectionCarouselComponent {
            export enum SortEnum {
                DateAsc = 'date_asc',
                DateDesc = 'date_desc',
                TitleAsc = 'title_asc',
                TitleDesc = 'title_desc',
                ClosingSoon = 'closing_soon',
                PriceDesc = 'price_desc',
                PriceAsc = 'price_asc',
                Popular = 'popular',
            }
        }
        export interface SectionsFaqComponent {
            id: number;

            __component: 'sections.faq';

            questions: {
                question: string;
                answer: string;
                id: number;
            }[];
        }
        export interface SectionsFilterComponent {
            id: number;

            __component: 'sections.filter';

            Title?: string;

            DefaultSort?: SectionsFilterComponent.DefaultSortEnum;

            HideTitle?: boolean;

            DefaultGenres?: string[];

            DefaultCategories?: string[];

            DefaultArtists?: string[];

            DefaultSubCategories?: string[];

            DefaultAutographed?: boolean;

            DefaultConditions?: string[];

            HasAuction?: boolean;

            Collections?: string[];

            OfferTypes?: string[]
        }
        export namespace SectionsFilterComponent {
            export enum DefaultSortEnum {
                DateAsc = 'date_asc',
                DateDesc = 'date_desc',
                TitleAsc = 'title_asc',
                TitleDesc = 'title_desc',
                ClosingSoon = 'closing_soon',
                PriceDesc = 'price_desc',
                PriceAsc = 'price_asc',
                Popular = 'popular',
            }
        }
        export interface SectionsFullWidthImageComponent {
            id: number;

            __component: 'sections.full-width-image';

            image: CMS.Image.Image;

            Link?: string;

            mobileImage?: CMS.Image.Image;

            text?: string;
        }
        export interface SectionsFullWidthImageSliderComponent {

            id: number;

            __component: 'sections.fw-image-slider';

            slides: SectionsFullWidthImageComponent[]
        }

        export interface SectionsHeroComponent {
            id: number;

            __component: 'sections.hero';

            Image: CMS.Image.Image;

            Text: string;

            Button: LinksButtonComponent;

            mobileImage?: CMS.Image.Image;

            SubText?: string;
        }
        export interface LinksButtonComponent {
            id?: number;

            text?: string;

            type?: LinksButtonComponent.TypeEnum;

            url?: string;

            SectionAnchor?: string;

            page?: {
                data: {
                    attributes: Omit<Page.Page, 'contentSections'>;
                };
            };
        }
        export namespace LinksButtonComponent {
            export enum TypeEnum {
                Primary = 'primary',
                Secondary = 'secondary',
            }
        }
        export interface SectionsHeroSliderComponent {
            id: number;

            __component: 'sections.hero-slider';

            slide: Array<ElementsHeroSlideComponent>;
        }
        export interface ElementsHeroSlideComponent {
            id?: number;

            title?: string;

            text?: string;

            image?: CMS.Image.Image;

            buttonText?: string;

            buttonUrl?: string;

            mobileImage?: CMS.Image.Image;
        }
        export interface SectionsItemsCarouselComponent {
            id: number;

            __component: 'sections.items-carousel';

            title?: string;

            titleLink?: string;

            type?: SectionsItemsCarouselComponent.TypeEnum;

            hideSliderOnMobile?: boolean;

            items?: string[];

            Limit?: number;

            Sort?: SectionsItemsCarouselComponent.SortEnum;

            FilterGenres?: string[];

            FilterArtists?: string[];

            FilterCategories?: string[];

            FilterAutographed?: boolean;
        }
        export interface SectionsBannerCarouselComponent {
            id: number;

            __component: 'sections.banner-carousel';

            title?: string;

            background?: string;

            type?: SectionsItemsCarouselComponent.TypeEnum;

            hideSliderOnMobile?: boolean;

            items?: string[];

            Limit?: number;

            Sort?: SectionsItemsCarouselComponent.SortEnum;

            FilterGenres?: string[];

            FilterArtists?: string[];

            FilterCategories?: string[];

            FilterAutographed?: boolean;
        }
        export namespace SectionsItemsCarouselComponent {
            export enum TypeEnum {
                Popular = 'popular',
                RecentlyViewed = 'recently viewed',
                ClosingSoon = 'closing soon',
                Custom = 'custom',
            }

            export enum SortEnum {
                DateAsc = 'date_asc',
                DateDesc = 'date_desc',
                TitleAsc = 'title_asc',
                TitleDesc = 'title_desc',
                ClosingSoon = 'closing_soon',
                PriceDesc = 'price_desc',
                PriceAsc = 'price_asc',
                Popular = 'popular',
            }
        }
    }
}
/* eslint-enable @typescript-eslint/no-namespace */
